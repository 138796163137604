<template>
  <div>
    <b-table bordered hover :items="proyectos" :fields="fields">
      <template #cell(posicion)="row">
        <div v-if="row.item.posicion" class="cell-posicion">
          <p @click="openEditPosicion(row.item)">{{ row.item.posicion }}</p>
        </div>
        <div v-else class="cell-posicion">
          <p @click="openEditPosicion(row.item)">sin posicion</p>
        </div>
      </template>
      <template #cell(acciones)="row">
        <div>
          <b-icon
            @click="
              $router.push({
                name: 'Actualizar Comercializacion',
                params: { id: row.item.id },
              })
            "
            v-b-tooltip.hover
            title="actualizar"
            icon="pencil"
          ></b-icon>
          <b-icon
            class="ml-2"
            @click="handleDeleteProyecto(row.item.id)"
            v-b-tooltip.hover
            title="eliminar"
            icon="trash"
          ></b-icon>
        </div>
      </template>
    </b-table>
    <b-modal
      v-model="modalEditPosicion"
      hide-footer
      @hidden="proyecto = { posicion: null }"
    >
      <b-overlay :show="loading" rounded="sm">
        <form @submit.prevent="handleUpdatePosicion()">
          <b-form-group label="Posicion" label-for="nombre">
            <b-form-input
              id="posicion"
              v-model.number="proyecto.posicion"
              type="number"
              required
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary"> Guardar </b-button>
        </form>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      modalEditPosicion: false,
      proyecto: { posicion: null },
      fields: [
        {
          key: "nombre",
          label: "Nombre",
        },
        {
          key: "direccion",
          label: "Dirección",
        },
        {
          key: "posicion",
          label: "Posicion",
        },
        {
          key: "acciones",
          label: "Acciones",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getProyectos: "comercializacion/getProyectos",
      deleteProyecto: "comercializacion/deleteProyecto",
      updatePosicion: "comercializacion/updatePosicion",
    }),
    openEditPosicion(proyecto) {
      this.proyecto = { ...proyecto };
      this.modalEditPosicion = true;
    },
    async handleUpdatePosicion() {
      this.loading = true;
      const res = await this.updatePosicion({
        id: this.proyecto.id,
        posicion: this.proyecto.posicion,
      });
      if (res) {
        this.$swal({
          title: "Actualizado",
          text: "Posicion actualizada",
          icon: "success",
        });
        this.modalEditPosicion = false;
      } else {
        this.$swal({
          title: "Error",
          text: "No se pudo actualizar la posicion",
          icon: "error",
        });
      }
      this.loading = false;
    },
    async handleDeleteProyecto(id) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "¡No podrás revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "¡Sí, eliminar!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const res = await this.deleteProyecto(id);
            if (res) {
              this.$swal("Exito", "Desarrollo eliminado", "success");
            } else {
              this.$swal("Error", "No se pudo eliminar el desarrollo", "error");
            }
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      proyectos: "comercializacion/proyectos",
    }),
  },
  mounted() {
    this.getProyectos();
  },
};
</script>
<style scoped>
.cell-posicion {
  cursor: pointer;
  text-align: center;
}
</style>
